import { getReclameAquiId } from "config";
import { useEffect } from "react";

const reclameAquiId = getReclameAquiId();

const injectReclameAquiScript = (reclameAquiId) => {
  const mainFooter = document.getElementById("reclame-aqui-badge-box")

  if (!mainFooter || !reclameAquiId) return;

  const reputationDiv = document.createElement("div");
  reputationDiv.id = "reputation-ra";

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "ra-embed-reputation";
  script.src = "https://s3.amazonaws.com/raichu-beta/selos/bundle.js";
  script.setAttribute("data-id", reclameAquiId);
  script.setAttribute("data-target", "reputation-ra");
  script.setAttribute("data-model", "1");

  reputationDiv.appendChild(script);

  mainFooter.appendChild(reputationDiv);

  return () => {
    mainFooter.removeChild(reputationDiv);
  };
};

export const useReclameAquiBadge = () => {
  useEffect(() => {
    const cleanup = injectReclameAquiScript(reclameAquiId);

    return () => {
      if (cleanup) cleanup();
    };
  }, [reclameAquiId]);

  return Boolean(reclameAquiId)
};
