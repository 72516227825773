import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid, useMediaQuery } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AddOrRemove from "./AddOrRemove";
import { domainColorStyle, getDomainMarketColor, getMenu } from "config";
import { ToDecimal } from "helpers/currency";
import { productURL ,notification, humanReadableWeight,countItensCart } from "helpers/util";
import { addToCart, removeFromCart } from "actions/cartActions";
import { openDeliveryMethod } from "actions/configsActions";
import appExclusive from "assets/img/icons/appExclusive.png";
import offerExplosion from "assets/img/icons/offerExplosion.png";
import ProductPrice from "./ProductPrice";
import ModalPreferences from "components/ModalPreferences/ModalPreferences";

import "./ProductCard.scss";
import OfferLabel from "components/OfferLabel";
import ModalOfferTakeMore from "components/ModalOfferTakeMore/ModalOfferTakeMore";
import { getDomainLogo } from "config";
import { track } from "helpers/pixelHelper";
import { sendAddedToCartEvent } from "helpers/analyticHelper";
import ModalLogin from "components/ModalLogin/ModalLogin";
import {getMixesFromCart } from "helpers/cartHelper";

const marketColor = getDomainMarketColor()

function ProductCard({
  product = {},
  amount,
  openDeliveryMethod,
  currentMarket,
  addToCart,
  removeFromCart,
  origin,
  mix,
  mixProductIndex,
  setMixProductIndex,
  updateAmountProduct,
  sliderStyle
}) {
  const deliveryMethod = useSelector(({ deliveryMethod }) => deliveryMethod.deliveryMethod)
  const deliveryMethodMaxItems = useSelector(
    ({deliveryMethod}) => deliveryMethod.deliveryMethod.max_items_amount)
  const cart = useSelector(({ cart }) => getMixesFromCart(cart.cart));
  const totalItemsDeliveryMethod = countItensCart(cart)
  const [shouldAnimateToShow, showProductAnimated] = useState(false);
  const history = useHistory();

  const [modalPreference, setModalPreference] = useState(false);
  const [modalOfferTakeMore, setModalOfferTakeMore] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width: 768px)");
  const brand = useSelector(({brand}) => brand.brand)
  const isValidMenu = getMenu()
  const showAvailableStock = Boolean(brand?.enable_available_stock && product?.available_stock)

  useEffect(() => {
    setTimeout(() => {
      showProductAnimated(!shouldAnimateToShow);
    }, 500);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handlePreferencesAndCart(preference) {
    setModalPreference(false);
    product.mix_item_preference = preference;
    addProductToCart(product);
  }
  function isBuyingXProductTakeYProductPerXPrice() {
    return (
      product.promotion_kind === "buying_x_product_take_y_product_per_z_price"
    );
  }

  const unavailableProduct = product.stock <= 0; 

  const addProductToCart = (product, quantity) => {
    switch (true) {
      case brand?.crm?.restrict_to_crm_logged_users &&
           !localStorage.getItem("crmUserCpf") &&
           brand.crm.company !== "propz":
        setModalLogin(true);
        break;
  
      case unavailableProduct:
        notification(`Produto indisponível (${product.description})`, "warning");
        break;
  
      case amount >= product.stock:
        notification("Quantidade máxima atingida para o item", "warning");
        break;
  
      case !deliveryMethod?.id:
        openDeliveryMethod(true);
        break;
  
      case deliveryMethodMaxItems && totalItemsDeliveryMethod >= deliveryMethodMaxItems:
        notification(`Limite de produtos no carrinho atingido (${deliveryMethodMaxItems})`, 'warning') 
        break;

      default:
        addToCart(product, origin, quantity);
        track("AddToCart", product);
        sendAddedToCartEvent(product);
        if(updateAmountProduct){
          updateAmountProduct(product.id, true, product, amount +1);
        }
        break;
    }
  };  

  const handleRemoveFromCart = (product) => {
    if(updateAmountProduct){
      updateAmountProduct(product.id, false, product, amount -1);
    }
    removeFromCart(product)
  }

  const highestPriority = product?.highestPriorityOffer();
  const offerBadge = brand
    ? brand.offerBadgeForKind(highestPriority.kind)
    : null;

  const promotionsLabels = isBuyingXProductTakeYProductPerXPrice()
    ? ["OFERTA", "LEVE +"]
    : product.promotion_label_array;

  const loadProductPageIfComesFromSearch = () =>{
    const historyLocation = history.location.pathname === `/loja/${currentMarket.id}`
    history.push(productURL(product, origin));
    if(!historyLocation){
      history.go(0)
    }
  }


  const cardWrapperStyles = {
    ...sliderStyle,
    height: Boolean(isMobileScreen && isValidMenu) ? "225px" : undefined
  }

  const productCardStyles = {
    opacity: unavailableProduct ? "50%" : 1,
    justifyContent: isValidMenu ? "space-evenly" : "space-between",
    padding: Boolean(isMobileScreen && isValidMenu) ? 0 : undefined
  };

  return (
    <Grid
      xs={6}
      sm={4}
      md={3}
      item
      className={`w-lg-20 store-card-product px-1 fade ${shouldAnimateToShow ? "show" : ""
      }`}
      style={cardWrapperStyles}
    >
      <div className="card-product" style={productCardStyles}>
        <img
          alt=""
          src={product.image}
          onClick={() => {loadProductPageIfComesFromSearch()}}
        />
        {product.kilogram_price && (
          <div
            className="text-right w-100 px-3"
            style={{ position: "absolute", top: "100px" }}
          >
            <span
              className="amount-in-kilo-label"
              style={{ ...domainColorStyle("background"), marginBottom: 10 }}
            >
              {humanReadableWeight(product.amount_in_kilo)}
            </span>
          </div>
        )}
        <div
          className="product-description"
          onClick={() => {loadProductPageIfComesFromSearch()}}
        >
          <p className="offer-price">
            {product.original_price > product.currentPrice() && (
              <>
                <span>R$</span> <del>{ToDecimal(product.original_price)}</del>
              </>
            )}
          </p>
          <ProductPrice price={product.currentPrice()} />

          {product.kilogram_price && (
            <p className="kilo-price" style={{ backgroundColor: marketColor }}>
              <span>R$</span> {ToDecimal(product.currentKilogramPrice())}
              /Quilo
            </p>
          )}
          <p>{product.description}</p>
        </div>
        {showAvailableStock ? (
          <Box
            className="product-available-stock"
            sx={{ backgroundColor: marketColor }}
          >
            {product.available_stock}
          </Box>
        ) : null}
        <div className="promotion-flag">
          {promotionsLabels &&
            promotionsLabels.map((item, index) =>
              index % 2 === 0 ? (
                <span className="promotion-text " key={index}>
                  {item}
                </span>
              ) : (
                <span
                  key={index}
                  style={domainColorStyle("color")}
                  className="promotion-text "
                >
                  {item}
                </span>
              )
            )}
        </div>

        {product.crmOffer() && <OfferLabel isOffer={true} useCrm={true} />}
        {product.offers?.length > 0 && brand && !product.crmOffer() && (
          <img
            alt=""
            className="offer"
            src={
              offerBadge
                ? offerBadge.image_url
                : highestPriority.kind === "app_exclusive"
                ? appExclusive
                : highestPriority.kind === "club_exclusive"
                ? getDomainLogo()
                : offerExplosion
            }
          />
        )}
        {product.has_free_delivery && (
          <span
            className="free-delivery"
            style={product.offers.length > 0 ? { top: "25%" } : { top: "5%" }}
          >
            Frete Grátis
          </span>
        )}
        {mix && (
          <div className="mix-toggle" style={{width: `${mix.length*10}%`}}>
            {mix.map((item, key) => (
              <button
                className={key === mixProductIndex && "selected-btn"}
                style={{ backgroundColor: marketColor }}
                onClick={() => setMixProductIndex(key)}
              />
            ))}
          </div>
        )}
        {!isValidMenu && 
          <>
            {!amount ? (
              <Button
                onClick={() => {
                  product.item_preference
                    ? setModalPreference(true)
                    : addProductToCart(product);
                  isBuyingXProductTakeYProductPerXPrice() &&
                    setModalOfferTakeMore(true);
                }}
                style={domainColorStyle("borderColor")}
              >
                {" "}
                <ShoppingCartIcon style={domainColorStyle("color")} />
                {!isMobileScreen && (
                  <span className="text" style={domainColorStyle("color")}>
                    Adicionar
                  </span>
                )}
              </Button>
            ) : (
              <AddOrRemove
                amount={amount}
                product={product}
                onAddClick={addProductToCart}
                onRemoveClick={handleRemoveFromCart}
              />
            )}
          </>
        }
      </div>
      <ModalPreferences
        isOpen={modalPreference}
        close={() => setModalPreference(false)}
        toggleModal={handlePreferencesAndCart}
        itemPreference={product.item_preference}
      />
      {brand && (
        <ModalLogin
          isOpen={modalLogin}
          toggleModal={() => setModalLogin(false)}
          crm={brand.crm}
        />
      )}
      {product.kind_for_promotion_calculation === "full_price_item" &&
        product.promotion_kind ===
          "buying_x_product_take_y_product_per_z_price" && (
          <ModalOfferTakeMore
            isOpen={modalOfferTakeMore}
            toggleModal={() => {
              setModalOfferTakeMore(false);
            }}
            product={product}
            addToCart={(product) => {
              addProductToCart(product);
            }}
          />
        )}
    </Grid>
  );
}
export { ProductCard };

export default connect(null, { addToCart, removeFromCart, openDeliveryMethod })(
  React.memo(ProductCard)
);
