import React from "react";
import { getEbitStoreID } from "config"; 
import { getDomainName } from "config";

const ebitID = getEbitStoreID();
const domainName = getDomainName()

const EbitBadge = () => {
  return ebitID ? (
    <a 
      id="seloEbit" 
      href={`http://www.ebit.com.br/${ebitID}/selo`} 
      rel="noreferrer" 
      target="_blank" 
      data-noop="redir(this.href);" 
      title={`Selo Ebit ${domainName}`} 
      onContextMenu={(e) => { 
        e.preventDefault(); 
        alert("Cópia proibida! A certificação Ebit é de uso exclusivo das lojas conveniadas Ebit.");
      }}
      style={{ display: "block", overflow: "hidden", position: "relative" }}
    >
      <img 
        src={`https://newimgebit-a.akamaihd.net/ebitBR/selo/img_${ebitID}.png`} 
        alt="Selo Ebit" 
        style={{ border: "0px", height: "70px" }} 
      />
    </a>
  ) : null;
};

export default EbitBadge;
