import React, { useCallback, useEffect, useState } from 'react';
import { getDomainMarketId } from 'config';
import { useSelector } from 'react-redux';
import { getAllPaymentTypes } from 'helpers/apis/marketInfo';
import { maskPhone } from 'helpers/util';
import { getMarketAppInfos, getFooterSectionInformation } from 'helpers/apiHelper';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./AdaptiveFooter.scss"
import ListSectionsFooter from '../ListSectionsFooter';
import SocialSectionsFooter from '../SocialSectionsFooter';
import PaymentSectionsFooter from '../PaymentSectionsFooter';
import Modal from 'new/components/Modal/Modal';
import { arrayHasContent } from 'helpers/util';


export default function AdaptiveFooter({ infoMarket }) {
  const brand = useSelector(({ brand }) => brand.brand);
  const currentMarket = useSelector(({ markets }) => markets);
  const brandMarkets = useSelector(({ brand }) => brand.brandMarkets);
  const [paymentImages, setPaymentImages] = useState([]);
  const [showModalWhoWeAre, setShowModalWhoWeAre] = useState(false);
  const [showModalTermsOfUse, setShowModalTermsOfUse] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState("");
  const [footerSections, setFooterSections] = useState([]);
  const [listInformationalData, setListInformationalData] = useState({
    showModal: false,
    message: ''
  });
  const webOrMobileFooterDisplay = useMediaQuery("(max-width: 750px)");
  const adjustableGridSize = 3
  const sortedSections = [...footerSections].sort((a, b) => a.position - b.position) 

  const getInfoSection = async () => {
    const filterSectionsWithContent = (sections) => {
      return sections.filter(section => {
        return section.kind === "payment" || (section.content && section.content.length > 0);
      })
    }
    try {
      const response = await getFooterSectionInformation(brand.id)
      const filteredSections = filterSectionsWithContent(response.footer_sections)
      setFooterSections(filteredSections)
 
    } catch (error) {
      console.log(error)
    }
  }
  const getTermsOfUse = async (marketID) => {
    const { market_app_infos } = await getMarketAppInfos(marketID)
    const { text } = market_app_infos.find((info) => info.title === "Termos") || ""
    setTermsOfUse(text)
  };

  const getMarketList = useCallback(async () => {
    const id = getDomainMarketId() || brandMarkets[0].id;
    getPaymentTypes(id);
  }, []);


  const getPaymentTypes = useCallback(async (id) => {
    const response = await getAllPaymentTypes(id);
    const allPaymentImages = getPaymentImages(response.data.payment_types);
    setPaymentImages(allPaymentImages);
  }, []);

  const getPaymentImages = (data) => {
    const paymentInfo = {
      online: [],
      credit: [],
      debit: [],
      food: [],
    }

    for (const item of data) {
      if (paymentInfo[item.kind].length > 1) continue
      paymentInfo[item.kind].push(item.image)
    }

    return [
      ...paymentInfo.online,
      ...paymentInfo.credit,
      ...paymentInfo.debit,
      ...paymentInfo.food,
    ]
  }

  useEffect(() => {
    getInfoSection();
    getMarketList();
    getTermsOfUse(currentMarket.id || brand.main_market_id);
  }, [brand.id])

  const handleWithInformationModals = (value, urlList, listBodyMessage) => {
    let url;
    switch (value) {
      case "who-we-are":
        setShowModalWhoWeAre(true);
        break;
      case "terms-of-use":
        setShowModalTermsOfUse(true);
        break;
      case "privacy-policy":
        url = "https://www.linx.com.br/aviso-de-privacidade";
        window.open(url, "_blank");
        break;
      case "new_page":
        window.open(urlList, "_blank")
        break;
      case "informative_modal":
        setListInformationalData({ showModal: true, message: listBodyMessage });
        break;
    }
  }


  return (
    <>
      <Grid container className='footer-main-new'>
        {!webOrMobileFooterDisplay && !arrayHasContent(footerSections) &&
          <>
            <Grid item xs={adjustableGridSize} className='support-footer-section-area'>
              <h6>SAC</h6>
              <Box className='footer-sac-area'>
                <Typography>{maskPhone(infoMarket.phone_number)}</Typography>
                <Typography>{maskPhone(infoMarket.second_phone_number)}</Typography>
                <Typography>{infoMarket.email}</Typography>
              </Box>
            </Grid>
            <Grid item xs={adjustableGridSize} className='support-footer-section-area'>
              <h6>INFORMAÇÕES</h6>
              <Box className='footer-information-button-modals'>
                {currentMarket.description &&
                  <Button 
                    id="who-we-are" value={currentMarket.description || infoMarket.description}
                    onClick={() => handleWithInformationModals("who-we-are")}>
                    Quem somos
                  </Button>}
                {termsOfUse &&
                  <Button onClick={() => handleWithInformationModals("terms-of-use")}>
                    Termos de Uso
                  </Button>}
                <Button onClick={() => handleWithInformationModals("privacy-policy")}>
                  Política de Privacidade
                </Button>
              </Box>
            </Grid>
          </>
        }

        {webOrMobileFooterDisplay && !arrayHasContent(footerSections) &&
          <>
            <Accordion elevation={0}>
              <AccordionSummary className='support-footer-section-area' expandIcon={<ExpandMoreIcon />}>
                <h6>SAC</h6>
              </AccordionSummary>
              <AccordionDetails>
                <Box className='footer-sac-area'>
                  <Typography>{maskPhone(infoMarket.phone_number)}</Typography>
                  <Typography>{maskPhone(infoMarket.second_phone_number)}</Typography>
                  <Typography>{infoMarket.email}</Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0}>
              <AccordionSummary className='support-footer-section-area' expandIcon={<ExpandMoreIcon />}>
                <h6>INFORMAÇÕES</h6>
              </AccordionSummary>
              <AccordionDetails className='footer-information-button-modals'>
                {currentMarket.description &&
                  <Button onClick={() => handleWithInformationModals("who-we-are")}>
                    Quem somos
                  </Button>}
                {termsOfUse &&
                  <Button onClick={() => handleWithInformationModals("terms-of-use")}>
                    Termos de Uso
                  </Button>}
                <Button onClick={() => handleWithInformationModals("privacy-policy")}>
                  Política de Privacidade
                </Button>
              </AccordionDetails>
            </Accordion>
            </>}


        {!sortedSections.length &&
          <PaymentSectionsFooter
            defaultPayment={sortedSections}
            adjustableGridSize={adjustableGridSize}
            images={paymentImages}/>
        }
      
        {sortedSections?.map((section) => {
          // Filtrar os objetos de responseTeste que correspondem à posição atual
          const filteredData = footerSections.filter(item => item.position === section.position);

          // Renderizar o componente correspondente à posição e kind, passando os dados filtrados
          switch (section.kind) {
            case 'list':
              return (
                <ListSectionsFooter
                  key={section.id}
                  webOrMobileFooterDisplay={webOrMobileFooterDisplay}
                  sections={filteredData}
                  adjustableGridSize={adjustableGridSize}
                  handleWithInformationModals={handleWithInformationModals}
                />
              );
            case 'payment':
              return (
                <PaymentSectionsFooter
                  key={section.id}
                  adjustableGridSize={adjustableGridSize}
                  images={paymentImages}
                  defaultPayment={sortedSections}
                />
              );
            case 'social':
              return (
                <SocialSectionsFooter
                  key={section.id}
                  sections={filteredData}
                  adjustableGridSize={adjustableGridSize}
                />
              );
            default:
              return null;
          }
        })}


      </Grid>

      <Modal
        isOpen={showModalWhoWeAre}
        toggleModal={() => setShowModalWhoWeAre(false)}>
        <h6>Quem Somos</h6>
        <Typography style={{margin: 10}}>
          {currentMarket.description || infoMarket.description}
        </Typography>
        <Box>
          <Button
            style={{float: 'right'}}
            variant="contained"
            color="primary"
            onClick={() => setShowModalWhoWeAre(false)}>
            Sair
          </Button>
        </Box>
      </Modal>

      <Modal
        isOpen={showModalTermsOfUse}
        toggleModal={() => setShowModalTermsOfUse(false)}>
        <h4>Termos de Uso</h4>
        <Typography style={{padding: "2%"}}>
          {termsOfUse}
        </Typography>
        <Box style={{float: 'right'}}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowModalTermsOfUse(false)}>
            Sair
          </Button>
        </Box>
      </Modal>

      <Modal
        isOpen={listInformationalData.showModal}
        toggleModal={() => setListInformationalData(false)}>
        <h4>Informações</h4>
        <Typography style={{padding: "2%"}}>
          {listInformationalData.message}
        </Typography>
        <Box style={{float: 'right'}}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setListInformationalData(false)}>
            Sair
          </Button>
        </Box>
      </Modal>
    </>
  )
}
