// modules
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { resetMarket } from "actions/setZoneForRequestedCep";
import { setDeliveryMethod } from "actions/setDeliveryMethod";
import { bindActionCreators } from "redux";
import { arrayHasContent, humanReadableTime, notification, showNotification } from "../../helpers/util";
import {
  getDeliveryMethods,
  getTaxFullAddress,
} from "../../helpers/apis/marketInfo";
import { Button } from "reactstrap";
import numberToCurrency from "helpers/currency";
import { domainColorStyle, getDomainMarketId } from "config";
import { useHistory, useParams } from "react-router-dom";
import Modal from "../Modal/modal";
import NeedsFullAddress from "./FullAddress";
import { openDeliveryMethod } from "actions/configsActions";
import { applyDeliveryMethodFunctions } from "helpers/models/deliveryMethod";

function DeliveryMethods(props) {
  const history = useHistory();
  const params = useParams();
  const {
    zone,
    markets,
    resetMarket,
    setDeliveryMethod,
    setModalDeliveryMethods,
    modalDeliveryMethods,
    openDeliveryMethod,
    onSelect
  } = props;

  const [data, setData] = useState([]);
  const [needsFullAddress, setNeedsFullAddress] = useState(false);
  const [number, setNumber] = useState("");
  const [street, setStreet] = useState(zone.street);
  const [chosenDeliveryMethod, setChosenDeliveryMethod] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const restrictDelivery = markets.restrict_delivery_methods_by_person_type
  const shouldSkipDeliveryMethodsModal = useSelector(({ brand }) => brand.brand?.should_skip_delivery_methods_modal)

  const goToStore = (DeliveryMethod) => {
    const coalescedDeliveryMethod = DeliveryMethod || {};
    setChosenDeliveryMethod(DeliveryMethod);
  
    if (coalescedDeliveryMethod.needs_full_address) {
      setNeedsFullAddress(true);
      return;
    }
  
    setDeliveryMethod(DeliveryMethod);
  
    if (!modalDeliveryMethods) {
      const domainMarketId = getDomainMarketId();
      history.push(`/loja/${domainMarketId}`);
      return;
    }
  
    setModalDeliveryMethods();
    openDeliveryMethod(false);
  
    if (onSelect) {
      onSelect();
      return;
    }
  
    if (!params.category && !params.product) {
      history.push(`/loja/${markets.id}`);
    }
  };

  const handleDeliveryMethods = (methods) => {
    if (shouldSkipDeliveryMethodsModal) {
      const availableMethods = methods.filter(method => method.available);
      const unavailableMethods = methods.filter(method => !method.available)
      
      if (unavailableMethods.length === 1) {
        setData(methods)
        return
      }
  
      if (availableMethods.length > 1) {
        setData(availableMethods);
        return;
      }
  
      goToStore(availableMethods[0]);
      return;
    }
  
    if (methods.length > 1) {
      setData(methods);
      return;
    }
  
    goToStore(methods[0]);
  };
  

  useEffect(() => {
    const personType = localStorage.getItem("personType")
    if (!restrictDelivery) {
      localStorage.removeItem("personType")
    } 
    const DeliveryData = async () => {
      const cep = zone ? zone.cep : "";
      const neighborhoodId = zone ? zone.neighborhood_id : "";
      const { data } = await getDeliveryMethods(
        markets.deliveryMode,
        cep,
        neighborhoodId,
        markets.id,
        personType
      );
     
      if (!arrayHasContent(data)) {
        notification(`Não há nenhum método de ${markets.deliveryMode === "delivery" ? "entrega" : "retirada"} disponível no momento.`)
        setModalDeliveryMethods();
        return
      }
      const deliveryMethods = applyDeliveryMethodFunctions(data); 
      handleDeliveryMethods(deliveryMethods)
    };
    DeliveryData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function reset() {
    delete localStorage.market_id;
    resetMarket();
    setModalDeliveryMethods();
  }

  async function fullAddressPress() {
    setIsLoading(true);
    const response = await getTaxFullAddress(
      markets.id,
      chosenDeliveryMethod.id,
      zone.cep,
      zone.neighborhood_id,
      street,
      number
    );
    if (response.status >= 200 && response.status <= 300) {
      const newDeliveryMethod = {
        ...chosenDeliveryMethod,
        tax: response.data.tax,
        fullAddressNumber: number,
      };
      setDeliveryMethod(newDeliveryMethod);
      setIsLoading(false);
      setModalDeliveryMethods();
      openDeliveryMethod(false);
      history.push(`/loja/${markets.id}`);
    } else {
      setIsLoading(false);
      showNotification(
        "top",
        "center",
        "Não conseguimos estimar o valor da entrega para o seu endereço.",
        "warning"
      );
      setNumber("");
      setStreet("");
    }
  }

  function taxLabel(item) {
    var taxLabel = "Taxa de entrega";

    if (item.mode === "drivethru") {
      taxLabel = "Taxa de retirada";
    }
    if (item.tax === null && item.tax_calculation_method === "correios") {
      return `${taxLabel}: à calcular`;
    }
    return `${taxLabel}: ${numberToCurrency(item.combinedTax(markets))}`;
  }
  function preparationTimeText(item) {
    if (item.mode === "drivethru") {
      return item.preparation_time
        ? `Retire na loja em: ${humanReadableTime(item.preparation_time)}`
        : "Retire na loja mediante agendamento";
    }
    return item.preparation_time
      ? `Receba em casa em: ${humanReadableTime(item.preparation_time)}`
      : "Receba em casa mediante agendamento";
  }

  const handleSelectDeliveryMethod = (method) => {
    if (shouldSkipDeliveryMethodsModal && !method.available) {
      notification("Método de entrega indisponível", "warning");
      return;
    }
    
    goToStore(method);
  };
  

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {data.map((item, index) => {
        return (
          <Button
            onClick={() => handleSelectDeliveryMethod(item)}
            key={index}
            style={{
              margin: 10,
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              textTransform: "capitalize",
            }}
          >
            <span
              className="reset-text-transform"
              style={{
                fontSize: 14,
                fontWeight: "bold",
                ...domainColorStyle("color"),
              }}
            >
              {item.display_name}
            </span>
            <span className="reset-text-transform" style={{ fontSize: 12 }}>
              {preparationTimeText(item)}
            </span>
            <span className="reset-text-transform" style={{ fontSize: 12 }}>
              {item.max_items_amount
                ? `Número máximo de itens: ${item.max_items_amount}`
                : "Leve quantos produtos quiser"}
            </span>
            <span className="reset-text-transform" style={{ fontSize: 12 }}>
              {item.max_distinct_items
                ? `Número máximo de itens distintos: ${item.max_distinct_items}`
                : "Sem limite de itens distintos"}
            </span>
            <span className="reset-text-transform" style={{ fontSize: 12 }}>
              {taxLabel(item)}
            </span>
            {item.serviceTax(markets) > 0 && (
              <span className="reset-text-transform" style={{ fontSize: 12 }}>
                Taxa de serviço: {numberToCurrency(item.serviceTax(markets))}
              </span>
            )}

            <span className="reset-text-transform" style={{ fontSize: 12 }}>
              Pedido minimo: {numberToCurrency(item.min_order_price)}
            </span>
          </Button>
        );
      })}
      <Button
        onClick={() => reset()}
        type="submit"
        className="btn-icon block btn-3"
        outline
        style={{
          margin: 10,
          ...domainColorStyle("color"),
          ...domainColorStyle("borderColor"),
        }}
      >
        <span className="btn-inner--text">Voltar</span>
      </Button>

      <Modal
        WrappedComponent={NeedsFullAddress}
        setStreet={setStreet}
        setNumber={setNumber}
        street={street}
        number={number}
        reset={reset}
        fullAddressPress={fullAddressPress}
        title="Selecione seu bairro!"
        showModal={needsFullAddress}
        isLoading={isLoading}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryMethods);

function mapStateToProps({ zone, markets }) {
  return { zone, markets };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetMarket,
      setDeliveryMethod,
      openDeliveryMethod,
    },
    dispatch
  );
}
