import React from "react";
import { Dialog, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const CupomVerdeDialog = ({ isOpen, setIsOpen, cupomVerdeUrl }) => {
  const closeModal = () => setIsOpen(false);

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
      <IconButton
        onClick={closeModal}
        aria-label="Close"
        style={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
      >
        <CloseIcon />
      </IconButton>

      <iframe
        src={cupomVerdeUrl}
        width="100%"
        height="1200px"
        style={{ border: "none" }}
      />
    </Dialog>
  );
};

export default CupomVerdeDialog;
