import config from "config";
import { sendSearchEvent } from "helpers/analyticHelper";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";
import { store } from "App";


function getUserIdAPICallUsingRedux() {
  const reduxState = store.getState()
  const customerId = reduxState.userInfo.id
  return customerId
}

export async function getItemByCodeBar(barcode, market_id) {
  const userCPF = localStorage.getItem("crmUserCpf");
  try {
    const item = await oAuthRequest.get(
      `${config.mappURLV2
      }/markets/${market_id}/items/by_barcode?barcode=${barcode}${userCPF ? `&cpf=${userCPF}` : ""
      }`
    );
    return item.data;
  } catch (error) {
    console.log("get item error");
  }
}

export const getItemBySlug = async (itemSlug, marketID) => {
  const userCPF = localStorage.getItem("crmUserCpf");
  const user = getUserIdAPICallUsingRedux()
  const url = `${config.mappURLV2}/markets/${marketID}/items/show_by_slug`
  const params = {
    cpf: userCPF,
    slug: itemSlug,
    customer_id: user
  }
  try {
    const response = await oAuthRequest.get(url, { params });
    return response.data;
  } catch (error) {
    throw error
  }
}

export async function getItemsByPromotionId(
  marketId,
  promotionId,
  exceptItemId = null
) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2
      }/markets/${marketId}/items/promotion_items?promotion_id=${promotionId}${exceptItemId && `&except_item_id=${exceptItemId}`
      }`
    );
    return response.data.items;
  } catch (error) {
    console.log("get item error");
  }
}

export async function getItemsByIds(itemIds, market_id) {
  const userCPF = localStorage.getItem("crmUserCpf");
  try {
    const item = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${market_id}/items?items_ids=${itemIds.join(
        ","
      )}${userCPF ? `&cpf=${userCPF}` : ""}`
    );
    return item.data;
  } catch (error) {
    console.log("get item error");
  }
}

export async function search(query, marketID, page, deliveryMethodID) {
  const user = getUserIdAPICallUsingRedux()
  const url = `${config.mappURLV2}/markets/${marketID}/items/search`
  const params = {
    query: query,
    page: page,
    cpf: localStorage.getItem("crmUserCpf"),
    customer_id: user,
    delivery_method_id: deliveryMethodID
  }

  try {
    const response = await oAuthRequest.get(url, { params })
    sendSearchEvent(query, true);
    return response.data;
  } catch (error) {
    console.log(error)
    sendSearchEvent(query, false);
    throw error
  }
}
